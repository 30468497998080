import * as React from 'react';
import queryString from 'query-string';
import useSWR from 'swr';
import { request } from 'graphql-request';

// Require the presentation component here, this is shared between the SSR template
// and the runtime preview template
import Page from '../../templates/presentation/post';

// Get the variables for the query
const variables =
  typeof window !== 'undefined'
    ? {
        id: queryString.parse(window.location.search).id,
      }
    : {};

const fetcher = (query) => request(process.env.GATSBY_WP_API, query, variables);

const PostPreview = () => {
  const { data, error } = useSWR(
    `
      query PostPreview($id: ID!) {
        post(id: $id, idType: DATABASE_ID ) {
          id
          content
          title
          slug
          date
          articleAcf {
            subtitle
            featuredImage {
              sourceUrl
            }
            hasAuthor
            author {
              ... on StaffMember {
                id
                slug
                title
                staffAcf {
                  profileImage {
                    sourceUrl
                  }
                }
              }
            }
          }
        }
      }
  `,
    fetcher
  );

  if (error) return <div>failed to load</div>;
  if (!data) return <div>loading...</div>;

  return <Page data={data.post} slug={data.slug} />;
};

export default PostPreview;
